import './team.css';
import ScheduleSection from '../schedule/schedule';
import { useTranslation } from 'react-i18next';

const TeamSection = () => {
  const { t } = useTranslation();

  return (
    <section className="team-section">
      <div className="team-container">
        <h2>{t('team-title')}</h2>
        <p className="team-description" dangerouslySetInnerHTML={{ __html: t('team-description') }} />
        <ScheduleSection />
      </div>
    </section>
  );
};

export default TeamSection;
