import './schedule.css';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

const ScheduleSection = () => {
  const { t } = useTranslation();

  function onCallClick(): void {
    ReactGA.send({ hitType: 'event', eventCategory: 'Schedule', eventAction: 'Schedule Click', eventLabel: t('schedule') });
    window.open('https://calendly.com/cloudsibyl/demo', '_blank');
  }

  return (
    <button className="call-button" onClick={onCallClick}>{t('schedule')}</button>
  );
};

export default ScheduleSection;
