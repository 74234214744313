import './finops.css';
import { useTranslation } from 'react-i18next';

const FinopsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="finops-section">
      <div className="finops-container">
        <h2 className="finops-title">{t('finops-title')}</h2>
        <p className="finops-description" dangerouslySetInnerHTML={{ __html: t('finops-description-part-1') }} />
        <p className="finops-description" dangerouslySetInnerHTML={{ __html: t('finops-description-part-2') }} />
      </div>
    </section>
  );
};

export default FinopsSection;
