// src/FeatureSection.tsx
import React from 'react';
import { FaCloud, FaLeaf, FaUserShield } from 'react-icons/fa';
import { AiOutlineProject, AiOutlineLineChart, AiOutlineRocket } from 'react-icons/ai';
import './feature.css';
import { useTranslation } from 'react-i18next';

const FeatureSection = () => {
  const { t } = useTranslation();

  const features = [
    {
      title: t('feature-cross-provider-title'),
      description: t('feature-cross-provider-description'),
      icon: <FaCloud />
    },
    {
      title: t('feature-granular-cost-title'),
      description: t('feature-granular-cost-description'),
      icon: <AiOutlineProject />
    },
    {
      title: t('feature-intuitive-interface-title'),
      description: t('feature-intuitive-interface-description'),
      icon: <AiOutlineRocket />
    },
    {
      title: t('feature-accountability-title'),
      description: t('feature-accountability-description'),
      icon: <FaUserShield />
    },
    {
      title: t('feature-carbon-footprint-title'),
      description: t('feature-carbon-footprint-description'),
      icon: <FaLeaf />
    },
    {
      title: t('feature-easy-setup-title'),
      description: t('feature-easy-setup-description'),
      icon: <AiOutlineLineChart />
    },
  ];

  return (
    <section className="feature-section">
      <h2>{t('feature-title')}</h2>
      <div className="feature-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeatureSection;
