import { useEffect, useState } from 'react';
import './timeline.css';
import timelineData_fr from './timeline-fr.json';
import timelineData_en from './timeline-en.json';
import { Chrono } from "react-chrono";
import { useTranslation } from 'react-i18next';

const TimelineSection = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    setData(i18n.language === 'fr' ? timelineData_fr : timelineData_en);
  }, [i18n.language]);
  return (
    <section className="timeline-section">
      <h2>{t('timeline-title')}</h2>
      <div className="timeline-section-container">
        {data && (
          <Chrono
            items={data}
            mode="VERTICAL_ALTERNATING" // Options: VERTICAL_ALTERNATING, HORIZONTAL, VERTICAL
            disableToolbar={true} // Options: true, false
            scrollable
            allowDynamicUpdate={true}
            cardHeight={160}
            theme={{
              primary: '#0b4b93', // Dark blue theme color
              secondary: '#fcfcfc', // Background for cards
              cardBgColor: '#F9F9F9',
              titleColor: '#0b4b93',
              titleColorActive: '#0b4b93',
              cardTitleColor: '#0b4b93'
            }}
            classNames={{
              cardTitle: 'my-card-title',
            }}

          />
        )}
      </div>
    </section>
  );
};

export default TimelineSection;
