import { useState } from 'react';
import './faq.css';
import faqs_en from './faq-en.json'; // Import the FAQs JSON data
import faqs_fr from './faq-fr.json'; // Import the FAQs JSON data
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function FAQSection() {
  const { t, i18n } = useTranslation();
  const faqs = i18n.language === 'fr' ? faqs_fr : faqs_en;
  // State for FAQs open/close
  const [openFAQ, setOpenFAQ] = useState<number | null>(null);

  // Function to toggle FAQ sections
  const toggleFAQ = (index: number) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <section className="faq-section">
      <h2>{t('faq-title')}</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <h3>{faq.question}</h3>
            <FontAwesomeIcon icon={openFAQ === index ? faChevronUp : faChevronDown} />
          </div>
          {openFAQ === index && (
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </section>
  );
}

export default FAQSection;
