// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';

const browserLanguage = navigator.language;
const language = browserLanguage.startsWith('fr') ? 'fr' : 'en'; 

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language, // default language
    fallbackLng: 'en', // fallback language in case the selected language translation is not found
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
