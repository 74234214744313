import './App.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { initGA } from './analytics';
import ReactGA from 'react-ga4';


import FAQSection from './modules/faq/faq';
import FeatureSection from './modules/feature/feature';
import InfoSection from './modules/info/info';
import TimelineSection from './modules/timeline/timeline';
import TeamSection from './modules/team/team';
import FooterSection from './modules/footer/footer';
import ScheduleSection from './modules/schedule/schedule';
import FinopsSection from './modules/finops/finops';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    initGA();  

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'fr' : 'en';
    ReactGA.send({ hitType: 'event', eventCategory: 'Language', eventAction: `Language Change to ${newLanguage}`, eventLabel: newLanguage });
    i18n.changeLanguage(newLanguage);
  };

  const imageUrl = t('screenshot_url');

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">
          <img src="/CloudSibyl-logo.png" alt="CloudSibyl Logo" />
        </div>

        <div className="header-buttons">
          <button className="lang-button" onClick={toggleLanguage}>
            {i18n.language === 'en' ? 'FR' : 'EN'}
          </button>
          <ScheduleSection />
        </div>
      </header>

      <main className="App-main">
        <h1 className="main-title">{t('title')}</h1>
        <p className="main-description">{t('description')}</p>
        <ScheduleSection />
        <section className="image-section">
          <div className="image-container">
            <img src={imageUrl} alt="Screenshot of the application" className="screenshot-image" />
          </div>
        </section>
        <InfoSection />
        <FeatureSection />
        <FAQSection />
        <FinopsSection />
        <TimelineSection />
        <TeamSection />
        <FooterSection />
      </main>
    </div>
  );
}

export default App;
